@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap');

.servicios {
  padding-top: 50px;
  padding-bottom: 100px;
  background-color: #F9F8EF;
  text-align: center;
  font-weight: 100;
  font-style: normal;
  color: #F9F8EF;
}

.service-cards {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  max-width: 1500px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.service-card {
  flex: 1;
  position: relative;
  min-height: 250px;
  border-radius: 8px;
  background-color: var(--card-color);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.card-content {
  position: relative;
  z-index: 2;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

/* Contenedor de waves */
.waves-container {
  position: absolute;
  left: 0;
  width: 100%;
  height: 30%;
  overflow: hidden;
  bottom: 1%; 

}

/* Waves SVG */
.waves {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: -7px;
  min-height: 50px;
  max-height: 100px;
}

/* Animation */
.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
}

.parallax > use:nth-child(1) {
  animation-delay: -8s;
  animation-duration: 7s;
}

.parallax > use:nth-child(2) {
  animation-delay: -8s;
  animation-duration: 10s;
}

.parallax > use:nth-child(3) {
  animation-delay: -2s;
  animation-duration: 13s;
}

.parallax > use:nth-child(4) {
  animation-delay: -10s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}

.service-card:hover {
  transform: translateY(-5px);
}

.service-card h2 {
  font-size: 20px;
  margin-bottom: 0.5rem;
  color: #F9F8EF;
}

.service-card p {
  font-size: 1rem;
  color: #F9F8EF;
}

@media (max-width: 768px) {
  .service-cards {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    max-width: 400px;
  }

  .service-card {
    width: 80%;
    margin-bottom: 20px;
  }

  .waves {
    height: 40px;
    min-height: 40px;
  }
}