@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.contacto {
  background-color: #2CBFBF;
  padding: 50px 150px 50px 100px;

  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 150;
  font-style: normal;
}

.contacto-info {
  width: 45%;
}

.contact-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 3rem;
}

.contact-description {
  font-size: 1.1rem;
  margin-bottom: 3rem;
  color: #F9F8EF;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-style: normal;
}

.contact-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #3E2956;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;

}

.contact-item {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  margin-bottom: 1rem;

}

.contact-icon {
  margin-right: 0.5rem;

}

.map iframe{
  border-radius: 8px;
}

@media (max-width: 768px) {
  .contacto {
    flex-direction: column;
    padding: 1rem 20px;
  }

  .contact-info, .map {
    width: 100%;
  }

  .map iframe {
    height: 300px; 
    width: 350px;
  }

  .contact-title {
    font-size: 2rem; 
  }

  .contact-description {
    font-size: 1rem;
  }

  .contact-item {
    font-size: 1rem;
  }
}