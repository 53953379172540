@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.hero {
  background: linear-gradient(to bottom, #3E2956, #F71973);
  color: #F9F8EF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 150px;
  padding-right: 200px;
  height: 600px;
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}

.hero-content h1 {
  font-size: 2.5rem;
}


.hero-image-large {
  width: 100%;
  max-width: 350px;
}

.hero-image-small {
  display: none; 
}

.hero-content {
  max-width: 600px; 
  margin-right: 2rem; 
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #F9F8EF;
  line-height: 1.5; 
}

.hero-btn {
  background-color: #F9F8EF;
  color: #3E2956;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.hero-btn:hover {
  color: #F71973;
  background-color: #fff;
}


@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    height: auto;
    text-align: center;
  }

  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .hero-image-large {
    display: none; 
  }

  .hero-image-small {
    display: block; 
    width: 150px; 
    margin-top: 2rem;
  }
}
