@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.footer {
    background-color: #3E2956;
    color: #F9F8EF;
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    width: 100%;
    height: 3.5rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .footer-links {
    display: flex;
    gap: 2rem;
    padding-top: 1rem;
    margin-right: 2.5rem;
  }

  .footer-link {
    color: #F9F8EF;
    text-decoration: none;
    font-size: 1rem;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }