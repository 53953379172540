@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.servicios-vista-wrapper {
  padding: 4rem 1rem;
  background-color: #F9F8EF;
  text-align: center;
  position: relative;
}

.servicios-vista h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #3E2956;
  margin-bottom: 3rem;
}

.servicios-vista p {
  font-size: 1.1rem;
  margin-bottom: 3rem;
}

.max-w-3xl {
  max-width: 900px;
  margin: 0 auto;
}

.servicio-expandible {
  width: 100%;
}

button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-bottom: 1rem;
  transition: background 0.4s ease, color 0.4s ease;
}
button:nth-child(1) {
  background-color: #F71973; 
}

button:nth-child(2) {
  background-color: #2CBFBF; 
}

button:nth-child(3) {
  background-color: #3E2956; 
}

button:nth-child(4) {
  background-color: #AD326D; 
}

button:hover {
  filter: brightness(1.1); 
}

button .flex {
  display: flex;
  align-items: center;
}

button span {
  font-size: 1.25rem;
  font-weight: 600;
  color: white;
}

button .w-6 {
  margin-right: 1rem;
  color: white;
}

button .w-5 {
  color: white;
}



.servicio-expandible .p-4 {
  background-color: #fafafa; 
  border-radius: 8px;
  margin-top: 0.5rem;
  padding: 2rem;
  display: block;
  text-align: left; 
}

.servicio-expandible h3 {
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: left; 
  color: #3E2956;
}


ul {
  padding-left: 1rem;
  list-style: none;
  margin-bottom: 1.5rem;
}

ul li::before {
  content: "•"; 
  color: #3E2956;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

ul li {
  font-size: 0.95rem;
  color: #4A4A4A;
  margin-bottom: 0.5rem;
  text-align: left; 
}

.servicios-vista h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #3E2956;
  margin-bottom: 2rem;
  text-align: center; 
}

.servicio-expandible {
  width: 100%;
  text-align: left; 
}

.subseccion {
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left; 
}


h3, h4 {
  text-align: left;
  margin-bottom: 1rem;
  color: #3E2956;
  font-weight: bold;
}


h4 {
  font-size: 1.2rem;
  margin-top: 1rem;
  color: #2CBFBF; 
}


.cta {
  background-color: #F9F8EF;
  padding: 3rem 1rem;
  border-radius: 12px;
  border-bottom: 2px solid #2CBFBF;
  margin-top: 4rem;
  text-align: center;
}

.cta h2 {
  font-size: 2rem;
  color: #3E2956;
  margin-bottom: 1rem;
}

.cta p {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 2rem;
}

.cta-btn {
  background-color: #2CBFBF;
  color: white;
  padding: 12px 24px;
  text-decoration: none;
  border-radius: 8px;
  display: inline-block;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.cta-btn:hover {
  background-color: #25a9a9;
}

.decorative-rabbit {
  position: absolute;
  width: 400px;
  height: 600px;
  background-image: url('../../public/assets/servicios.png');
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.1;
  pointer-events: none; 
}

.decorative-rabbit.top-right {
  top: 0;
  right: 0;
  transform: rotate(180deg);
  z-index: 1;
}

.decorative-rabbit.bottom-left {
  bottom: 0;
  left: 10px;
  z-index: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .servicios-vista h2 {
    font-size: 2rem;
  }

  .servicio-expandible .p-4 {
    padding: 1rem;
  }

  button {
    padding: 1rem;
  }
  
  .cta {
    padding: 2rem 1rem;
  }

  .cta h2 {
    font-size: 1.75rem;
  }

  .cta p {
    font-size: 1rem;
  }

  .cta-btn {
    padding: 10px 20px;
  }

  .decorative-rabbit.top-right,
  .decorative-rabbit.bottom-left {
    width: 200px;
    height: 300px;
    opacity: 0.15;
  }
}
