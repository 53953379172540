@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.decorative-rabbit-nosotros {
  position: absolute;
  width: 400px;
  height: 600px;
  background-image: url('../../public/assets/nosotros.png');
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.1;
  pointer-events: none; 
}


.servicio-text {
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: center; 
  max-width: 800px;  
  margin: 0 auto;   
  color: #333;
}

.valores-section {
  margin-top: 40px;
}

.valores-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 30px;
  justify-items: center; 
  align-items: center; 
}

.valor-card {
  position: relative;
  width: 100%;
  max-width: 400px;
  height: 250px; 
  perspective: 1000px;
  transition: margin-bottom 0.3s ease; 
}

.valor-card:hover {
  margin-bottom: 20px;
}

.valor-front, .valor-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 8px;
  transition: transform 0.6s;
  padding: 20px; 
  box-sizing: border-box; 
}

.valor-front {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: white; 
}

.valor-back {
  transform: rotateY(180deg);
  background-color: #F9F8EF;
  border-radius: 8px;
  border: 4px solid #333; 
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: #333; 
}

.valor-card:nth-child(1) .valor-back {
  border-color: #F71973; 
}

.valor-card:nth-child(2) .valor-back {
  border-color: #2CBFBF;
}

.valor-card:nth-child(3) .valor-back {
  border-color: #3E2956; 
}

.valor-card:nth-child(4) .valor-back {
  border-color: #F71973; 
}

.valor-card:nth-child(5) .valor-back {
  border-color: #2CBFBF;
}

.valor-card:nth-child(6) .valor-back {
  border-color: #3E2956; 
}

.valor-card:nth-child(1) .valor-front {
  background-color: #F71973;
}

.valor-card:nth-child(2) .valor-front {
  background-color: #2CBFBF;
}

.valor-card:nth-child(3) .valor-front {
  background-color: #3E2956;
}

.valor-card:nth-child(4) .valor-front {
  background-color: #F71973;
}

.valor-card:nth-child(5) .valor-front {
  background-color: #2CBFBF;
}

.valor-card:nth-child(6) .valor-front {
  background-color: #3E2956;
}

.valor-card:hover .valor-front {
  transform: rotateY(180deg);
}

.valor-card:hover .valor-back {
  transform: rotateY(0deg);
}

.valor-card h3 {
  color: white; 
}

.valores-section {
  max-width: 1200px; 
  margin: 0 auto; 
}

.valor-card p {
  font-size: 1rem;
  line-height: 1.5;
}

.decorative-rabbit-nosotros.top-right {
  top: 0;
  right: 0;
  transform: rotate(180deg);
  z-index: 1;
}

.decorative-rabbit-nosotros.bottom-left {
  bottom: 0;
  left: 10px;
  z-index: 1;
}
.cta {
  position: relative;
  z-index: 1;
}



.about-us-image img {
  width: 100%;
  max-width: 800px;
  height: auto;
  margin-bottom: 2rem;
  border-radius: 10px;
}



.cta {
  text-align: center;
  background-color: #F9F8EF;
  padding: 50px 20px;
  border-radius: 10px;
  border-bottom: 4px solid #2CBFBF;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
}


.cta h2 {
  font-size: 2.5rem;
  color: #2CBFBF;
}

.cta p {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 20px;
}

.cta-btn {
  background-color: #2cbfbf;
  color: white;
  padding: 15px 30px;
  border-radius: 10px;
  text-decoration: none;
  font-weight: bold;
}

.cta-btn:hover {
  background-color: #28afaf;
}

@media (max-width: 768px) {
  .about-us {
    flex-direction: column;
    text-align: center;
  }

  .about-us-text,
  .about-us-image {
    width: 100%;
  }

  .about-us-image img {
    width: 100%;
    max-width: 300px;
  }


  .valor-back p {
    font-size: 0.1rem; 
    line-height: 1;
  }

}
